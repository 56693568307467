import React from 'react';
import { Typography, Box} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Vector from '../assets/icons/Vector.png'
import Icon from '../assets/icons/Icon.png'

const CertificateDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const certificateDetails = location.state;

    const dateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    const genderMap = ['Keine Angabe', 'Rüde', 'Hündin'];
    const sizeMap = ['Keine Angabe', 'S', 'M', 'L', 'XL'];

    return (
        <Box width='100%'>
            <Box mb={2}
                style={{
                    display: 'flex', alignItems: 'center', color: '#5A6872'
                }}
                onClick={() => navigate('/übersicht')}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft" />
                </Box>

                <Typography
                    sx={{
                        font: 'Open Sans',
                        fontWeight: 700,
                        lineHeight: '25.2px',
                        fontSize: '18px',
                    }}
                >
                    Zurück zur Übersicht
                </Typography>
            </Box>

            <Typography  mb={2}
                sx={{
                    font: 'Open Sans',
                    fontWeight: 700,
                    lineHeight: '33.6px',
                    fontSize: '24px'
                }}
            >
                Leistungsurkunde
            </Typography>

            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                <Box p={2}>
                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Rufname
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {certificateDetails?.hundrufname ? certificateDetails?.hundrufname : 'Keine Angabe'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Eigentümer Name
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {`${certificateDetails?.vorname} ${certificateDetails?.name}`}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Zwingername
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {certificateDetails?.hundname ? certificateDetails?.hundname : 'Keine Angabe'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Geschlecht
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {genderMap[certificateDetails?.hundgeschlecht]}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Chipnummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {certificateDetails?.chipnr}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Wurftag
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {certificateDetails?.hundwurftag ? new Date(certificateDetails?.hundwurftag).toLocaleDateString('de-DE', dateOptions) : 'Keine Angabe'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Rasse
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {certificateDetails?.rasse ? certificateDetails?.rasse : 'Keine Angabe'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Schulterhöhe/Agility Klasse
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {sizeMap[certificateDetails?.size]}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Abstammungsnachweis
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {certificateDetails?.hundrasse === 1 ? 'Ja' : 'Nein'}
                        </Typography>
                    </Box>

                    {(() => {if(certificateDetails?.hundrasse === 1) {return (<>
                        
                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Zuchtbuchnunmmer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {certificateDetails?.zbnr ? certificateDetails?.zbnr : 'Keine Angabe'}
                        </Typography>
                    </Box>
                        
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '8px 4px 8px 4px',
                            background: '#FFFFFF',
                            flexDirection:'column'
                        }}
                    >
                        <Box mr={1}>
                            <img src={Icon} alt="icon" />
                        </Box>

                        <Box>
                            <Typography 
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 700,
                                    lineHeight: '16.8px',
                                    fontSize: '12px',
                                    color: '#5A6872'
                                }}
                            >
                                Nachweislich reinrassig
                            </Typography>

                        </Box>
                    </Box>
                    </>)}})()}
                    
                </Box>
            </Box>

        </Box>
    )
}

export default CertificateDetails;