import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Form from '../common/form/Form.jsx';

import { login } from './auth-api.js';
import useAuth from '../../hooks/useAuth.js';
import { jwtDecode } from 'jwt-decode';

const Login = function () {

	const [err, setErr] = useState('');

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const { setAuth } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const from = location?.state?.from.pathname ?? '/übersicht';



	const onSubmit = async (user) => {
		try {
			const { data } = await login(user);

			const decodedToken = jwtDecode(data?.token);

			localStorage.setItem('user', JSON.stringify(decodedToken));
			localStorage.setItem('token', data.token);
			setAuth({ user: decodedToken, token: data.token });
			navigate(from, { replace: true });

		} catch (error) {
			setErr(error?.response?.data?.message);
		}
	};

	const formData = [
		{
			label: 'E-Mail-Adresse / Benutzername',
			id: 'username',
			required: 'Erforderliches Feld',
			fullWidth: true,
		},
		{
			label: 'Passwort',
			id: 'password',
			type: 'password',
			required: 'Erforderliches Feld',
			showPassword: showPassword,
			handleClickShowPassword: handleClickShowPassword,
			handleMouseDownPassword: handleMouseDownPassword,
		},
	];

	return (
		<Container maxWidth="sm" sx={{ paddingY: '144px', justifyContent: 'center', textAlign: 'center' }}>
			<Typography
				my={4} ml={1}
				sx={{
					font: 'Open Sans',
					fontWeight: 700,
					lineHeight: '36.4px',
					fontSize: '26px',
				}}
			>Login
			</Typography>
			<Form formData={formData} onSubmit={onSubmit} responseError={err}>
				<Box mt={4}>
					<Button
						type="submit"
						variant="contained"
						fullWidth
						sx={{height: '36px'}}
					>
						<Typography
							sx={{
								font: 'Open Sans',
								fontWeight: 700,
								lineHeight: '19.6px',
								fontSize: '14px',
							}}
						>
							Einloggen
						</Typography>
					</Button>
				</Box>
			</Form>
			
			<Box mt={2}
                 style={{
                     display: 'flex',
                     alignItems: 'center',
                     color: '#5A6872'
                 }}
                 onClick={() => navigate('/forgotpw')}
            >
                <Typography
                    sx={{
                        font: 'Open Sans',
                        fontWeight: 700,
                        lineHeight: '25.2px',
                        fontSize: '18px',
                    }}
                >
                    Passwort vergessen?
                </Typography>
            </Box>
		</Container>
	);

};

export default Login;
