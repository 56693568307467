import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Vector from '../assets/icons/Vector.png'
import CardComponent from '../components/itemCards/ItemCard';

const AssocitaionDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const certificates = location.state.certificates;
    const membershipDetails = location.state.item;

    const dateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    return (
        <Box width='100%'>
            <Box mb={2}
                style={{
                    display: 'flex', alignItems: 'center', color: '#5A6872'
                }}
                onClick={() => navigate('/übersicht')}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft" />
                </Box>

                <Typography
                    sx={{
                        font: 'Open Sans',
                        fontWeight: 700,
                        lineHeight: '25.2px',
                        fontSize: '18px',
                    }}
                >
                    Zurück zur Übersicht
                </Typography>
            </Box>

            <Typography mb={2}
                sx={{
                    font: 'Open Sans',
                    fontWeight: 700,
                    lineHeight: '33.6px',
                    fontSize: '24px'
                }}
            >
                Mitgliedschaftsdetails
            </Typography>

            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                <Box p={2}>
                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Verein
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {membershipDetails?.verein_name}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Adresse
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {membershipDetails?.verein_strasse}<br/>
                            {membershipDetails?.verein_plz} {membershipDetails?.verein_ort}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            E-Mail-Adresse
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {membershipDetails?.verein_email}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Vereinsnummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {membershipDetails?.vereins_nr}
                        </Typography>
                    </Box>

                    {(() => {if(membershipDetails?.funktionaer) {return (<>
                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Funktion im Verein
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {membershipDetails?.funktionen}
                        </Typography>
                    </Box>
                    </>)}})()}

                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Status Mitgliedschaft
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {membershipDetails?.beitrag_kuerzel} ({membershipDetails?.beitrag_desc})
                        </Typography>
                    </Box>

                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Mitgliedsnummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {membershipDetails?.mitglied_nr}
                        </Typography>
                    </Box>

                </Box>
            </Box>

            <Box>
                <Typography variant="subtitle1" mb={1} sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Leistungsurkunden ({certificates ? certificates?.length : 0})</Typography>
            </Box>

            <Box>
                {
                    certificates?.map((certificate, index) =>
                        <Box key={index}>
                            <Box>
                                <CardComponent item={certificate} isCertificate={true} certificates={certificates} />
                            </Box>
                        </Box>
                    )

                }
            </Box>

        </Box>
    )
}

export default AssocitaionDetails;