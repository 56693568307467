import React from 'react';
import {
	Navigate, Outlet, useNavigate, useLocation,
} from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import useAuth from '../../hooks/useAuth';

const RequireAuth = function () {
	const { auth, setAuth } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();

	if (auth?.token) {
		const { exp } = jwtDecode(auth?.token);
		if (exp < (new Date().getTime() + 1) / 1000) {
			localStorage.clear();
			setAuth({ user: null, token: null });
			navigate('login', { replace: true });
		}
	}

	const render = () => {
		if (auth?.token) {
			return <Outlet />;
		}
		return <Navigate to="/login" state={{ from: location }} replace />;
	};

	return render();
};

export default RequireAuth;
