import React from 'react';
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';

const CardContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F0F4F8',
    padding: '20px',
    borderRadius: '8px',
    width: '100%',
    marginBottom: 10
});

const Circle = styled('div')({
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#9ECD2B',
    marginRight: '10px',
    alignSelf: 'center',
    flexShrink: 0,
});

const EluCardComponent = ({ item }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/elu', { state: item });
    }

    return (
        <Box style={{ display: 'flex' }} >
            <CardContainer>
                <Circle />
                <Typography
                    sx={{
                        font: 'Open Sans',
                        fontWeight: 700,
                        lineHeight: '19.6px',
                        fontSize: 14
                    }}>
                    {item?.trn_tid ? `NR: ${item?.trn_tid} / ${item?.trn_nam}` : item?.trn_nam}
                </Typography>
                <ChevronRightIcon sx={{ marginLeft: 'auto' }} />
            </CardContainer>
        </Box>
    );
};

export default EluCardComponent;
