import React
    from 'react';
import {
    Typography,
    Box,
    Button
} from '@mui/material';
import {
    useNavigate,
    useLocation
} from 'react-router-dom';
import Vector
    from '../assets/icons/Vector.png'

const UserDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const userDetails = location.state;

    const dateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    const genderMap = ['', 'Frau ', 'Herr ', ''];

    return (
        <Box width='100%'>
            <Box mb={2}
                 style={{
                     display: 'flex',
                     alignItems: 'center',
                     color: '#5A6872'
                 }}
                 onClick={() => navigate('/übersicht')}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft"/>
                </Box>

                <Typography
                    sx={{
                        font: 'Open Sans',
                        fontWeight: 700,
                        lineHeight: '25.2px',
                        fontSize: '18px',
                    }}
                >
                    Zurück zur Übersicht
                </Typography>
            </Box>

            <Typography mb={2}
                        sx={{
                            font: 'Open Sans',
                            fontWeight: 700,
                            lineHeight: '33.6px',
                            fontSize: '24px'
                        }}
            >
                Person
            </Typography>

            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                <Box p={2}>
                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Name
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {genderMap[userDetails.gender]} {userDetails.title ? userDetails?.title + ' ' : ''}{`${userDetails?.firstname} ${userDetails?.lastname}`}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Personen-Nummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {userDetails?.pnumber}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Geburtsdatum
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {new Date(userDetails?.birthdate).toLocaleDateString('de-DE', dateOptions)}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Straße und Hausnummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {`${userDetails?.street} ${userDetails?.snumber != null && !userDetails?.snumber.includes(userDetails?.snumber) ? userDetails?.snumber : ''}`}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            PLZ & Wohnort
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {`${userDetails?.zip} ${userDetails?.city}`}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Land
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {userDetails?.state}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            E-Mail
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {userDetails?.email}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Telefon
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 400,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {userDetails?.phone}
                        </Typography>
                    </Box>

                    {(() => {if(userDetails?.funktionaer) {return (<>
                        <Box>
                            <Typography
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 700,
                                    lineHeight: '16.8px',
                                    fontSize: '12px',
                                    color: '#5A6872'
                                }}
                            >
                                Funktion im Verband/Landesverband/Kreisgruppe

                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 400,
                                    lineHeight: '22.4px',
                                    fontSize: '16px'
                                }}
                            >
                                <div style={{whiteSpace: "pre-line"}}>{userDetails?.funktionen}</div>
                            </Typography>
                        </Box>
                    </>)}})()}

                    {(() => {if(userDetails?.leistungsrichter) {return (<>
                        <Box>
                            <Typography
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 700,
                                    lineHeight: '16.8px',
                                    fontSize: '12px',
                                    color: '#5A6872'
                                }}
                            >
                                Leistungsrichter für folgende Sportarten
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 400,
                                    lineHeight: '22.4px',
                                    fontSize: '16px'
                                }}
                            >
                                <div style={{whiteSpace: "pre-line"}}>{userDetails?.sportarten}</div>
                            </Typography>
                        </Box>
                    </>)}})()}
                </Box>
            </Box>
        </Box>
    )
}

export default UserDetails;

