import React, {useEffect, useState} from 'react';
import UserService from './user-api';
import {Typography, Grid, Box} from '@mui/material';
import useAuth from '../../hooks/useAuth';
import CardComponent from '../itemCards/ItemCard';
import UserCardComponent from '../itemCards/userCard';
import EluCardComponent from '../itemCards/eluCard';

const HomePage = function () {

    const [userDetails, setUserDetails] = useState(null);
    const [userMemberships, setUserMemberships] = useState(null);
    const [userCertificates, setUserCertificates] = useState(null);
    const [userElu, setUserElu] = useState(null);
    const [user, setUser] = useState(null);

    const {auth} = useAuth();

    useEffect(() => {
        const getUserDetails = async () => {
            try {
                setUser(auth?.user);

                const isOnline = navigator.onLine;
                if (isOnline) {
                    const {data} = await UserService.getUserData();
                    if (data && data.data) {
                        setUserDetails(data.data);
                        localStorage.setItem('userdetails', JSON.stringify(data.data));
                    }
                } else {
                    const cachedData = localStorage.getItem('userdetails');
                    if (cachedData) {
                        setUserDetails(JSON.parse(cachedData))
                    } else {
                        setUserDetails([]);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        };

        const getUserData = async () => {
            try {
                setUser(auth?.user);
                
                const isOnline = navigator.onLine;
                if (isOnline) {
                    const {data} = await UserService.getCertificateData();
                    if (data && data.data.length > 0) {
                        setUserCertificates(data.data);
                        localStorage.setItem('certificates', JSON.stringify(data.data));
                    }
                } else {
                    const cachedData = localStorage.getItem('certificates');
                    if (cachedData) {
                        setUserCertificates(JSON.parse(cachedData))
                    } else {
                        setUserCertificates([]);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        };

        const getMemberData = async () => {
            try {
                setUser(auth?.user);

                const isOnline = navigator.onLine;
                if (isOnline) {
                    const {data} = await UserService.getMemberData();
                    if (data && data.data.length > 0) {
                        setUserMemberships(data.data);
                        localStorage.setItem('memberships', JSON.stringify(data.data));
                    }
                } else {
                    const cachedData = localStorage.getItem('memberships');
                    if (cachedData) {
                        setUserMemberships(JSON.parse(cachedData))
                    } else {
                        setUserMemberships([]);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        };
        
        const getEluData = async () => {
            try {
                setUser(auth?.user);

                const isOnline = navigator.onLine;
                if (isOnline) {
                    const {data} = await UserService.getEluData();
                    if (data && data.data.length > 0) {
                        setUserElu(data.data);
                        localStorage.setItem('elu', JSON.stringify(data.data));
                    }
                } else {
                    const cachedData = localStorage.getItem('elu');
                    if (cachedData) {
                        setUserElu(JSON.parse(cachedData))
                    } else {
                        setUserElu([]);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        };

        getUserData();
        getMemberData();
        getUserDetails();
        getEluData();
    }, []);

    return (
        <Box>
            <Grid container spacing={2} style={{display: 'flex', flexDirection: 'column'}}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={1} sx={{fontWeight: 'bold', fontSize: '1.1rem'}}>Meine Daten</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <UserCardComponent item={userDetails}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={1} sx={{fontWeight: 'bold', fontSize: '1.1rem'}}>Mitgliedschaften ({userMemberships ? userMemberships?.length : 0})</Typography>
                </Grid>
                {
                    userMemberships?.map((membership, index) =>
                        <Grid item xs={12} sm={6} key={index}>
                            <CardComponent item={membership} isCertificate={false}  certificates={userCertificates}/>
                        </Grid>
                    )

                }
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={1} sx={{fontWeight: 'bold', fontSize: '1.1rem'}}>Leistungsurkunden ({userCertificates ? userCertificates?.length : 0})</Typography>
                </Grid>
                {
                    userCertificates?.map((certificate, index) =>
                        <Grid item xs={12} sm={6} key={index}>
                            <CardComponent item={certificate} isCertificate={true}/>
                        </Grid>
                    )

                }
            
            <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={1} sx={{fontWeight: 'bold', fontSize: '1.1rem'}}>eLU ({userElu ? userElu?.length : 0})</Typography>
                </Grid>
                {
                    userElu?.map((elu, index) =>
                        <Grid item xs={12} sm={6} key={index}>
                            <EluCardComponent item={elu}/>
                        </Grid>
                    )

                }
            </Grid>
        </Box>

    )
};

export default HomePage;
