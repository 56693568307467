import React from 'react';
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';

const CardContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F0F4F8',
    padding: '20px',
    borderRadius: '8px',
    width: '100%',
    marginBottom: 10
});

const Circle = styled('div')({
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#9ECD2B',
    marginRight: '10px',
    alignSelf: 'center',
    flexShrink: 0,
});

const CardComponent = ({ item, isCertificate = false, certificates }) => {

    const navigate = useNavigate();

    const handleClick = (isCertificate) => {
        if (isCertificate) {
            navigate('/leistungsurkunde', { state: item });
        } else {
            navigate('/mitgliedschaftsdetails', { state: {item, certificates} });
        }
    }

    return (
        <Box style={{ display: 'flex' }} onClick={() => handleClick(isCertificate)}>
            <CardContainer>
                    <Circle />
                <Typography
                    sx={{
                        font: 'Open Sans',
                        fontWeight: 700,
                        lineHeight: '19.6px',
                        fontSize: 14
                    }}>
                    {!isCertificate ? (
                        item?.verein_name + ` (${item?.vereins_nr})`
                    ) : (
                        (item?.hundrufname ? item?.hundrufname : `Kein Rufname`) + ` (NR: ${item?.nummer})`
                    )}
                </Typography>
                <ChevronRightIcon sx={{ marginLeft: 'auto' }} />
            </CardContainer>
        </Box>
    );
};

export default CardComponent;
