import api from '../../api/api';

const getCertificateData = (controllerSignal) => api.get(`/urkunde`, { signal: controllerSignal });
const getMemberData = (controllerSignal) => api.get(`/mitgliedschaft`, { signal: controllerSignal });
const getUserData = (controllerSignal) => api.get(`/userdetail`, { signal: controllerSignal });
const getEluData = (controllerSignal) => api.get(`/elu`, { signal: controllerSignal });

const UserService = {
    getCertificateData,
    getMemberData,
    getUserData,
    getEluData
};

export default UserService;
